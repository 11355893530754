import { format } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { toAbsoluteUrl } from '../../../../../_metronic/helpers/AssetHelpers';
import { api } from '../../../../modules/api/api';
import PrintCard from '../../../../modules/components/card/PrintCard';
import ImageWithFallback from '../../../../modules/components/image/ImageWithFallback';
import labelValue from '../../../../modules/components/labelValue/LabelValue';
import { useToast } from '../../../../modules/components/toast/ToastContext';
import BuyerMessage from './sections/BuyerMessage';
import History from './sections/History';
import ShippingInfo from './sections/ShippingInfo';
import { receiptTemplate } from './sections/templates/receiptTemplate';
import { refundTemplate } from './sections/templates/refundTemplate';

type Props = {
  type?: 'PREVIEW' | 'REPORT';
  // type 'PREVIEW' is when user config preview in portal
  // type 'REPORT' is when user enter the link
  iCS_id?: string;
  previewConfig?: DisplayConfig;
  initialConfig?: CompellingEvidenceConfig;
}

export type DisplayConfig = {
  config: {
    email: {
      confirmation: boolean,
      delivery: boolean,
      refund: boolean,
    },
    info: {
      tracking_url: boolean,
      buyer_message: boolean,
      product_photo: boolean,
      delivery_signed: boolean,
    },
    policy: {
      cancellation: boolean,
      refund: boolean,
      delivery: boolean,
      terms_and_conditions: boolean,
    },
  }
}

export type CompellingEvidenceConfig = {
  config?: {
    info?: {
      buyer_message?: boolean,
      product_photo?: boolean,
      delivery_signed?: boolean,
    },
    policy?: {
      cancellation?: boolean,
      refund?: boolean,
      delivery?: boolean,
      terms_and_conditions?: boolean,
    },
  }
}

export default function Detail({ type = 'REPORT', iCS_id, previewConfig, initialConfig }: Props) {
  const params = useParams();
  const icsId = type === 'PREVIEW' ? iCS_id : params.id
  const intl = useIntl();
  const { setToast } = useToast();
  const [displayConfig, setDisplayConfig] = useState<DisplayConfig>()

  // Fetch Data
  const { data, status, refetch } = useQuery(['compellingEvidence', icsId], async () => {
    try {
      const { result, status } = await api(
        `get_compelling_evidence/${icsId}`,
        {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(type === 'PREVIEW' ? initialConfig : {}) // return original data if pass-in empty object
        },
        { token: undefined, refresh: undefined },
        'ics',
        'MERCHANT'
      ).then((resp) => {
        return { result: resp?.json(), status: resp.status };
      });
      if (status === 200) return result;
    } catch (err) {
      const response = await err?.response?.json();
      setToast({
        show: true,
        type: 'Danger',
        header: intl.formatMessage({ id: 'MESSAGE.ERROR' }),
        message: response?.message ?? err.message,
      });
    }
  });
  const results = data?.results;
  const merchantConfig = results?.config;
  const evidenceConfig = results?.evidence?.config;
  const latestReportHistoryDate = results?.evidence?.history?.filter((history) => history?.event === 'EVIDENCE_CREATED')?.pop()?.time;

  const cancellationPolicy = results?.product_info?.policy?.find((policy) => policy?.type === 'cancellation')
  const refundPolicy = results?.product_info?.policy?.find((policy) => policy?.type === 'refund')
  const deliveryPolicy = results?.product_info?.policy?.find((policy) => policy?.type === 'delivery')
  const tAndCPolicy = results?.product_info?.policy?.find((policy) => policy?.type === 'terms and conditions')

  const haveTrackingUrl = results?.product_info?.tracking_url?.length > 0 ? true : false;

  const shippingInfoRes = useQuery(['shippingInfo', data], async () => {
    try {
      if (results && haveTrackingUrl) {
        const { result, status } = await api(
          `transaction_shipment`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify({
              url: results?.product_info?.tracking_url[0]
            }),
          },
          { token: undefined, refresh: undefined },
          'ics',
          'MERCHANT'
        ).then((resp) => {
          return { result: resp?.json(), status: resp.status };
        });
        if (status === 200) return result;
      }
    } catch (err) {
      const response = await err?.response?.json();
      setToast({
        show: true,
        type: 'Danger',
        header: intl.formatMessage({ id: 'MESSAGE.ERROR' }),
        message: response?.message ?? err.message,
      });
    }
  });
  const shippingInfo = shippingInfoRes?.data?.results?.data;
  const shipmentSiteSupported = shippingInfo?.site_domain === 't.17track.net';

  useEffect(() => {
    if (type === 'PREVIEW' && previewConfig !== undefined) {
      setDisplayConfig(previewConfig)
    }
    if (type === 'REPORT' && evidenceConfig !== undefined) {
      setDisplayConfig({ config: evidenceConfig })
    }
  }, [previewConfig, evidenceConfig])

  const [imgModalVisible, setImgModalVisible] = useState(false);
  // Get URL of buyer's uploaded image
  const rawImgPath: string | undefined = results?.history?.find(
    (item) => item.event === 'CASE_CREATED' && item.issue?.startsWith('items')
  )?.request?.filename;
  const parsedImgURL =
    rawImgPath &&
    process.env.REACT_APP_ICS_BUYER_IMG_BASE_URL +
    rawImgPath.split('/').pop();

  // Set the title for the printed page
  useEffect(() => {
    if (results?.transaction?.merchant_name && latestReportHistoryDate) {
      document.title = `Austreme Representment Evidence (${results?.transaction?.merchant_name}) ${format(new Date(latestReportHistoryDate), 'yyyyMMdd')}`;
    }
  }, [results, latestReportHistoryDate]);

  return (
    <>
      {status === 'success' && results && (
        <div className='print-exact-color bg-white w-1000px mx-auto'>
          <div className='container'>
            {results && merchantConfig && evidenceConfig && displayConfig && latestReportHistoryDate &&
              <>
                {/* Cover Page */}
                <PrintCard
                  breakPage={true}
                  backgroundImg={true}
                  style={{ backgroundImage: `url('${toAbsoluteUrl('/media/background/evidence-report-cover.png')}')` }}>
                  <div className="text-start ms-20 mt-20 pt-12">
                    <div className='fs-2hx text-gray-800 mb-6'>
                      Compelling Evidence
                    </div>
                    <div>
                      <div className='mb-4'>
                        <div className='text-gray-800 fw-bold fs-5'>Merchant Descriptor:</div>
                        <div className='text-muted fs-5'>{results?.transaction?.merchant_name}</div>
                      </div>
                      <div className='mb-4'>
                        <div className='text-gray-800 fw-bold fs-5'>Release Date:</div>
                        <div className='text-muted fs-5'>{format(new Date(latestReportHistoryDate), 'yyyy-MM-dd')}</div>
                      </div>
                      <div className='mb-4'>
                        <div className='text-gray-800 fw-bold fs-5'>Last Updated At:</div>
                        <div className='text-muted fs-5'>{format(new Date(), 'yyyy-MM-dd')}</div>
                      </div>
                    </div>
                  </div>
                </PrintCard>

                {displayConfig.config.email.confirmation === true &&
                  <PrintCard title={'Order Confirmation'} breakPage={true}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: receiptTemplate(results, merchantConfig, 'RECEIPT')
                      }}
                    />
                  </PrintCard>}

                {displayConfig.config.email.delivery === true &&
                  <PrintCard title={'Update Delivery Status'} breakPage={true}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: receiptTemplate(results, merchantConfig, 'SHIPPED')
                      }}
                    />
                  </PrintCard>}

                {displayConfig.config.email.refund === true &&
                  <PrintCard title={'Update Refund Information'} breakPage={true}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: refundTemplate(results)
                      }}
                    />
                  </PrintCard>}


                {displayConfig.config.info.tracking_url === true && haveTrackingUrl &&
                  <PrintCard title={'Shipping and Delivery Information'} breakPage={true}>
                    <div>
                      {shippingInfoRes.isLoading && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}
                      {(shippingInfoRes?.status === 'success' && shipmentSiteSupported && shippingInfo) && <ShippingInfo data={shippingInfo} tracking_url={results?.product_info?.tracking_url?.[0]} />}
                      {(shippingInfoRes?.status === 'success' && shipmentSiteSupported && shippingInfo === undefined) && 'Get tracking info fail, please try again.'}
                      {shippingInfoRes?.status === 'success' && shipmentSiteSupported !== true && <div className='mb-2'>{labelValue('Tracking URL', results?.product_info?.tracking_url?.[0])}</div>}
                    </div>
                  </PrintCard>}

                {displayConfig.config.info.product_photo === true && results.product_info.list.some((product) => product?.info_url !== "") &&
                  <PrintCard title={'Product Photo'} breakPage={true}>
                    {results.product_info.list.map((product) => <div>
                      {labelValue(product?.name, product?.info_url)}
                      <ImageWithFallback src={product?.photo_url} className='w-50' />
                    </div>)}
                  </PrintCard>}

                {displayConfig.config.info.delivery_signed === true && results.product_info?.delivery_signed &&
                  <PrintCard title={'Signed Delivery'} breakPage={true}>
                    {labelValue(`Signed Date: ${results.product_info?.delivery_signed_date}`, results.product_info?.delivery_signed)}
                    {results.product_info?.delivery_signed && <ImageWithFallback src={results.product_info?.delivery_signed} fallback={''} className='w-50' />}
                  </PrintCard>}

                {(Object.values(displayConfig.config.policy).some(value => value === true)) &&
                  <PrintCard title={'Policy'} breakPage={true}>
                    {displayConfig.config.policy.cancellation === true && cancellationPolicy?.url &&
                      labelValue(
                        cancellationPolicy?.title ?? 'Cancellation Policy',
                        cancellationPolicy?.url
                      )}
                    {displayConfig.config.policy.refund === true && refundPolicy?.url &&
                      labelValue(
                        refundPolicy?.title ?? 'Refund Policy',
                        refundPolicy?.url
                      )}
                    {displayConfig.config.policy.delivery === true && deliveryPolicy?.url &&
                      labelValue(
                        deliveryPolicy?.title ?? 'Delivery Policy',
                        deliveryPolicy?.url
                      )}
                    {displayConfig.config.policy.terms_and_conditions === true && tAndCPolicy?.url &&
                      labelValue(
                        tAndCPolicy?.title ?? 'Terms and Conditions',
                        tAndCPolicy?.url
                      )}
                  </PrintCard>}

                <PrintCard title={'Communication History'} breakPage={true}>
                  <History
                    data={results}
                    refetch={refetch}
                    onViewImage={() => setImgModalVisible(true)}
                  />
                </PrintCard>

                {displayConfig.config.info.buyer_message === true &&
                  <PrintCard title={'Buyer Message'} breakPage={true}>
                    <BuyerMessage icsId={icsId} />
                  </PrintCard>}

                {/* Last Page */}
                <PrintCard
                  showNoDataText={false}
                  breakPage={true}
                  backgroundImg={true}
                  style={{ backgroundImage: `url('${toAbsoluteUrl('/media/background/evidence-report-end.png')}')` }}>
                </PrintCard>
              </>
            }
          </div>
        </div>
      )}
    </>
  );
}
