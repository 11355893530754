/* eslint-disable react/jsx-no-target-blank */
import { useFilter } from '../../FilterContext'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import MultiSelection from '../../components/MultiSelection';
import SearchInputGroup from '../../components/SearchInputGroup';
import { useIntl } from 'react-intl';
import SingleSelection from '../../components/SingleSelection';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { api } from '../../../../api/api';
import { useAuth } from '../../../../auth';

interface SelectType {
  readonly value: string;
  readonly label: string;
}

export function RequestsFilter() {
  const params = useParams()
  // const { auth, refresh } = useAuth();
  // const intl = useIntl()
  const { globalFilter, setGlobalFilter } = useFilter()

  // const [clientListOptions, setClientListOptions] = useState([]);

  // const { data, status } = useQuery(
  //   ['beceiptMerchants'],
  //   async () => {
  //     const result = await api(
  //       `list_merchant`,
  //       {
  //         method: 'POST',
  //         headers: {
  //           'content-type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           filters: {},
  //           sort_order: { name: "merchant_id", sort: -1 },
  //           limit: 9999,
  //           offset: 0,
  //         }),
  //       },
  //       { token: auth.accessToken, refresh },
  //       'ics'
  //     ).then((resp) => resp?.json())
  //     return result;
  //   }
  // )

  // // client list
  // useEffect(() => {
  //   if (status === 'success' && data?.results?.data !== undefined) {
  //     const clientOptions = data.results.data.length > 0 ?
  //       new Set(data.results.data.map(
  //         (data) => data.merchant_id)) : new Set();

  //     setClientListOptions(Array.from(clientOptions).map(
  //       (merchantId: string) => { return { value: merchantId, label: merchantId } }));
  //   };
  // }, [data, status])

  const newMessageOptions = [{ value: 'yes', label: 'Yes' }]

  return (
    <>
      <div className={(clsx(params.id ? 'blur' : ''))}>
        <div className='d-flex'>
          {/* TODO: backend permission for client list */}
          {/* Client ID */}
          {/* <MultiSelection
            title='Client ID'
            globalFilterValue={globalFilter?.['beceiptRequestList']?.merchant_id?.values}
            options={clientListOptions}
            onChange={(e) => {
              setGlobalFilter({ ...globalFilter, beceiptRequestList: { ...globalFilter.beceiptRequestList, merchant_id: { type: "in", values: e } } });
            }} /> */}

          {/* Buyer Request */}
          <SingleSelection
            title='Buyer Request'
            globalFilterValue={globalFilter?.beceiptRequestList?.['refund.request']?.values}
            options={[
              { value: 'FULL_REFUND', label: 'Full Refund' },
              { value: 'PARTIAL_REFUND', label: 'Partial Refund' },
              { value: 'EXCHANGE', label: 'Exchange' },
              { value: 'NOT_REFUND', label: 'Not Refund' },
              { value: 'OTHERS', label: 'Others' },
            ]}
            isClearable
            onChange={(e) => {
              setGlobalFilter({
                ...globalFilter,
                beceiptRequestList: {
                  ...globalFilter.beceiptRequestList,
                  'refund.request':
                    typeof e?.value === 'string' ? { type: 'in', values: [e] } : undefined,
                },
              });
            }}
          />

          {/* Refund Status */}
          <SingleSelection
            title='Refund Status'
            globalFilterValue={globalFilter?.beceiptRequestList?.['refund.status']?.values}
            options={[
              { value: 'FULL_REFUND', label: 'Full Refund' },
              { value: 'PARTIAL_REFUND', label: 'Partial Refund' },
              { value: 'EXCHANGE', label: 'Exchange' },
              { value: 'NOT_REFUND', label: 'Not Refund' },
              { value: 'OTHERS', label: 'Others' },
              { value: '', label: '—' },
            ]}
            isClearable
            onChange={(e) => {
              setGlobalFilter({
                ...globalFilter,
                beceiptRequestList: {
                  ...globalFilter.beceiptRequestList,
                  'refund.status':
                    typeof e?.value === 'string' ? { type: 'in', values: [e] } : undefined,
                },
              });
            }}
          />

          {/* New Message */}
          <SingleSelection
            title='New Message'
            globalFilterValue={newMessageOptions.find((options) => options.value === globalFilter?.beceiptRequestList?.['unread']?.value)}
            options={newMessageOptions}
            isClearable
            onChange={(e) => {
              setGlobalFilter({
                ...globalFilter,
                beceiptRequestList: {
                  ...globalFilter.beceiptRequestList,
                  'unread':
                    typeof e?.value === 'string' ? { type: 'contains', value: e.value } : undefined,
                },
              });
            }}
          />

          {/* Search */}
          <SearchInputGroup
            inputs={[ // key = filter key
              { key: 'transaction.order_reference', title: 'Order Reference' },
            ]}
            section='beceiptRequestList' // globalFilter section key
            globalFilterValue={globalFilter}
            onSubmit={(searchValues) => setGlobalFilter({ ...globalFilter, beceiptRequestList: { ...globalFilter.beceiptRequestList, ...searchValues } })}
            onClear={(searchValues) => setGlobalFilter({ ...globalFilter, beceiptRequestList: { ...globalFilter.beceiptRequestList, ...searchValues } })}
          />
        </div>
      </div>
    </>
  )
}
