import { useState } from 'react';
import { valueHandler } from '../../../../../../_metronic/helpers/AssetHelpers';
import Time from '../../../../../modules/components/time/Time';
import TimelineItem from '../../../../../modules/components/timeline/TimelineItem';
import { EventValues, RefundStatus, Event, mapHistoryMessage, CompellingEvidenceEvent, mapIssueMessage } from '../../../transactions/detail/sections/types';
import { refundStatus } from '../../../transactions/mappings';

interface Props {
  data: any;
  refetch: () => void;
  onViewImage: () => void;
}

/**
 * Extract refund event and replace its `refund` property from string to object.
 * Original `refund` property syntax: `"status,amount,currency"`.
 * Note that amount is `0` for `Not Refund` and `false` for `Full Refund`.
 */
type SplitRefundEvent = Omit<Extract<CompellingEvidenceEvent, { event: 'REFUND_UPDATED' }>, 'refund'> & {
  refund:
  | { status: Exclude<RefundStatus, 'Partial Refund'> }
  | { status: 'Partial Refund'; amount: number; currency: string }
  | null;
};

type GroupedEvents = {
  date: string;
  events: ((Exclude<CompellingEvidenceEvent, { event: 'REFUND_UPDATED' }> | SplitRefundEvent) & {
    historyIndex: number;
  })[];
}[];

const BADGE_LOOKUP: Record<
  EventValues,
  Pick<React.ComponentProps<typeof TimelineItem>, 'badgeColor' | 'badgeType'>
> = {
  TRANSACTION_CREATED: { badgeColor: 'text-primary' },
  INITIAL_RECEIPT_SENT: { badgeColor: 'text-primary' },
  TRACK_INFO_ENQUIRY: { badgeColor: 'text-primary' },
  TRACK_INFO_REQUESTED: { badgeColor: 'text-primary' },
  TRACK_INFO_UPDATED: { badgeColor: 'text-success' },
  TRACK_RECEIPT_SENT: { badgeColor: 'text-primary' },
  REPORT_ISSUE: { badgeColor: 'text-warning' },
  CASE_CREATED: { badgeColor: 'text-danger' },
  REFUND_UPDATED: { badgeColor: 'text-success' },
  DELIVERY_SIGNED_UPDATED: { badgeColor: 'text-success' },
  CLOSED: { badgeColor: 'text-muted' },
};

export default function History({ data, refetch, onViewImage }: Props) {
  const [isExpanded, setIsExpanded] = useState(true);
  const UNEXPANDED_MAX_EVENTS = 10;
  const history = data.history;

  // Assuming history array is sorted by date, group history by date and parse refund
  const groupedEvents: GroupedEvents = [];
  history.forEach((event: CompellingEvidenceEvent, index) => {
    const formattedDate = event.time.split('T')[0];
    if (!groupedEvents.length || groupedEvents.at(-1).date !== formattedDate) {
      groupedEvents.push({ date: formattedDate, events: [] });
    }

    if (event.event === 'REFUND_UPDATED') {
      let refund: SplitRefundEvent['refund'] = null;
      if (event?.refund) {
        const splitRefund = event.refund.split(',');
        refund = {
          status: splitRefund[0] as RefundStatus,
          amount: Number(splitRefund[1]),
          currency: splitRefund[2],
        };
      }
      groupedEvents.at(-1).events.push({ ...event, refund, historyIndex: index });
    } else {
      groupedEvents.at(-1).events.push({ ...event, historyIndex: index });
    }
  });

  function toggleIsExpanded() {
    setIsExpanded(!isExpanded);
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body p-9'>
        {groupedEvents.map((group, index) => (
          <>
            {/* Ellipsis Button: Outside Timeline */}
            {/* {
              // Show if timeline is expanded
              // AND # of entries > # of entries to show in unexpanded timeline
              // AND the first entry in this date group is the first entry in history to hide in the unexpanded view
              // e.g., UNEXPANDED_MAX_EVENT = 3, then show if current entry is the 3rd entry in history
              !isExpanded &&
              history.length > UNEXPANDED_MAX_EVENTS &&
              history[UNEXPANDED_MAX_EVENTS - 1].time === group.events[0].time && (
                <>
                  <div className='separator my-2 mt-1 ' />
                  <div className='d-flex justify-content-center'>
                    <button
                      className='btn btn-icon btn-link btn-color-muted btn-active-color-primary'
                      onClick={toggleIsExpanded}
                    >
                      <i className='bi bi-three-dots-vertical fs-2x' />
                    </button>
                  </div>
                </>
              )
            } */}

            {/* Timeline */}
            {(isExpanded ||
              group.events[0].historyIndex < UNEXPANDED_MAX_EVENTS - 1 ||
              group.events.at(-1).historyIndex === history.length - 1) && (
                // Display timeline event/s if timeline is expanded,
                // OR 1st event of this group is among the entries to show in unexpanded timeline,
                // OR last event of this group is the last entry in history
                <div key={index}>
                  {/* Date Header */}
                  {group.events[0].historyIndex !== 0 && <div className='separator my-2' />}
                  <h5>
                    <Time date={new Date(group.events[0].time)} format='MMMM d, yyyy' />
                  </h5>

                  {/* Timeline of event/s for that date */}
                  <div className='timeline-label mb-6'>
                    {group.events.map((item, index) => (
                      <>
                        {(isExpanded ||
                          item.historyIndex < UNEXPANDED_MAX_EVENTS - 1 ||
                          item.historyIndex === history.length - 1) && (
                            <TimelineItem
                              date={new Date(item.time)}
                              showDate={item.event === 'TRANSACTION_CREATED' ? false : true}
                              badgeColor={BADGE_LOOKUP[item.event].badgeColor}
                              badgeType={BADGE_LOOKUP[item.event]?.badgeType}
                            >
                              {item.event === 'CASE_CREATED' ? (
                                <div className='vstack'>
                                  <span className='fw-bolder'>{mapHistoryMessage(item.event)}</span>
                                  <span>Issue: {mapIssueMessage(item.issue)}</span>
                                  <span>Request: {refundStatus[item?.refund]}</span>
                                  {/* Buyer upload image */}
                                  {item.issue.startsWith('items') && item?.filename && (
                                    <>
                                      Buyer Upload:
                                      <div>
                                        <img
                                          src={
                                            process.env.REACT_APP_ICS_BUYER_IMG_BASE_URL +
                                            item?.filename.split('/').pop()
                                          }
                                          alt='buyer upload image'
                                          className='w-250px rounded'
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              ) : item.event === 'REFUND_UPDATED' && item.refund ? (
                                <div className='vstack'>
                                  <span className='fw-bolder'>{mapHistoryMessage(item.event)}</span>
                                  <span>{valueHandler(item?.refund?.status)}</span>
                                  {item?.refund?.status === 'Partial Refund' && (
                                    <span>
                                      {item.refund.currency} {item.refund.amount.toFixed(2)}
                                    </span>
                                  )}
                                </div>
                              ) : (
                                <span>{mapHistoryMessage(item.event)}</span>
                              )}
                            </TimelineItem>
                          )}

                        {/* Ellipsis Button: Inside Timeline */}
                        {/* {!isExpanded &&
                          history.length > UNEXPANDED_MAX_EVENTS &&
                          index !== 0 &&
                          item.historyIndex === UNEXPANDED_MAX_EVENTS - 1 && (
                            <div className='d-flex justify-content-center mt-n7'>
                              <button
                                className='btn btn-icon btn-link btn-color-muted btn-active-color-primary'
                                onClick={toggleIsExpanded}
                              >
                                <i className='bi bi-three-dots-vertical fs-2x' />
                              </button>
                            </div>
                          )} */}
                      </>
                    ))}
                  </div>
                </div>
              )}
          </>
        ))}

        {/* Minimize Timeline Button */}
        {/* {isExpanded && (
          <div className='d-flex justify-content-center mt-n5'>
            <button
              className='btn btn-link btn-color-muted btn-active-color-primary btn-sm'
              onClick={toggleIsExpanded}
            >
              Show Less <i className='bi bi-chevron-compact-up fs-3'></i>
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
}
