import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useLayout } from '../core';
import { DrawerComponent } from '../../assets/ts/components';

import { AlertsFilter } from '../../../app/modules/components/filter/ethoca/alertsFilter/AlertsFilter';
import { DescriptorsFilter } from '../../../app/modules/components/filter/ethoca/descriptorsFilter/DescriptorsFilter';
import { ClientsFilter } from '../../../app/modules/components/filter/ethoca/clientsFilter/ClientsFilter';
import { EnquiriesFilter } from '../../../app/modules/components/filter/ethoca/enquiriesFilter/EnquiriesFilter';

import { UsersFilter } from '../../../app/modules/components/filter/user/usersFilter/UsersFilter';

// import { DuplicateFilter } from '../../../app/modules/components/filter/monitor/duplicateFilter/DuplicateFilter';
// import { EthocaDuplicateFilter } from '../../../app/modules/components/filter/monitor/ethocaDuplicateFilter/ethocaDuplicateFilter';
// import { RdrRecalledCaseFilter } from '../../../app/modules/components/filter/monitor/rdrRecalledCaseFilter/RdrRecalledCaseFilter';
import { AuditLogsFilter } from '../../../app/modules/components/filter/ethoca/auditLogsFilter/AuditLogsFilter';

import { CasesFilter as RdrCasesFilter } from '../../../app/modules/components/filter/verifi/casesFilter/CasesFilter';
import { ClientsFilter as RdrClientsFilter } from '../../../app/modules/components/filter/verifi/clientsFilter/ClientsFilter';
import { CaidsFilter as RdrCaidsFilter } from '../../../app/modules/components/filter/verifi/caidsFilter/CaidsFilter';

// import { ClientsFilter as BeceiptClientsFilter } from '../../../app/modules/components/filter/beceipt/clientsFilter/ClientsFilter';
import { TransactionsFilter } from '../../../app/modules/components/filter/beceipt/transactionsFilter/TransactionsFilter';
import { RequestsFilter as BeceiptRequestsFilter } from '../../../app/modules/components/filter/beceipt/requestsFilter/RequestsFilter';

// import { DuplicateArnFilter } from '../../../app/modules/components/filter/settlement/duplicateArn/DuplicateArnFilter';
// import { RdrRecalledCaseFilter } from '../../../app/modules/components/filter/settlement/rdrRecalledCaseFilter/RdrRecalledCaseFilter';
// import { ClientsFilter as StatisticClientsFilter } from '../../../app/modules/components/filter/settlement/clientsFilter/ClientsFilter';

const Content: React.FC = ({ children }) => {
  const { classes } = useLayout();
  const location = useLocation();
  const params = useParams();
  const path = params['*'];

  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);
  return (
    <div id='kt_content_container' className={clsx(classes.contentContainer.join(' '))}>
      <div>
        {path == 'ethoca/alerts/list' ? <AlertsFilter /> : ''}
        {path == 'ethoca/descriptors/list' ? <DescriptorsFilter /> : ''}
        {path == 'ethoca/clients/list' || path == 'ethoca/clients/tree' ? <ClientsFilter /> : ''}
        {path == 'ethoca/enquiries/list' ? <EnquiriesFilter /> : ''}
        {/* {path == 'ethoca/users/list' ? <UsersFilter /> : ''} */}
        {/* {path == 'ethoca/audit-logs/list' ? <AuditLogsFilter /> : ''} */}

        {path == 'rdr/cases/list' ? <RdrCasesFilter /> : ''}
        {path == 'rdr/clients/list' ? <RdrClientsFilter /> : ''}
        {path == 'rdr/caids/list' ? <RdrCaidsFilter /> : ''}

        {path == 'beceipt/transactions/list' ? <TransactionsFilter /> : ''}
        {/* {path == 'beceipt/clients/list' ? <BeceiptClientsFilter /> : ''} */}
        {path == 'beceipt/requests/list' ? <BeceiptRequestsFilter /> : ''}

        {/* {path == 'users/list' ? <UsersFilter /> : ''}
        
        {path == 'monitor/duplicate/list' ? <DuplicateFilter /> : ''}
        {path == 'monitor/duplicate-ethoca/list' ? <EthocaDuplicateFilter /> : ''}

        {path == 'settlement/duplicate-arn/list' ? <DuplicateArnFilter /> : ''}
        {path == 'settlement/recall-rdr/list' ? <RdrRecalledCaseFilter /> : ''}
        {path == 'settlement/clients/list' ? <StatisticClientsFilter /> : ''} */}
      </div>
      <div>{children}</div>
    </div>
  );
};

export { Content };
