import { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { useAuth } from '../modules/auth';

const PrivateRoutes = () => {
  // Icon will be navigate to alert list if no permission
  // const { currentUser, logout } = useAuth()
  // const statisticPermission = currentUser?.permissions?.find((permissions) => permissions?.service === 'STATISTICS');
  // const haveDashBoardPermission = statisticPermission?.access?.some((access) => access === 'dashboard.read') ?? false;

  const AlertsList = lazy(() => import('../pages/ethoca/alerts/list/List'));
  const UploadStatus = lazy(() => import('../pages/ethoca/alerts/upload-status/list/List'));
  const AlertsDetail = lazy(() => import('../pages/ethoca/alerts/detail/Detail'));
  const DescriptorList = lazy(() => import('../pages/ethoca/descriptors/list/List'));
  const ClientList = lazy(() => import('../pages/ethoca/client/list/List'));
  // const ClientTree = lazy(() => import('../pages/ethoca/client/tree/Tree'))
  const ClientDetail = lazy(() => import('../pages/ethoca/client/detail/Detail'));
  const EnquiriesList = lazy(() => import('../pages/ethoca/enquiries/list/Lists'))
  const EnquiriesCreateForm = lazy(() => import('../pages/ethoca/enquiries/form/EnquiriesCreateForm'))
  // const AuditLogsList = lazy(() => import('../pages/ethoca/auditLogs/list/List'))

  const RdrCasesList = lazy(() => import('../pages/verifi/cases/list/List'));
  const RdrClientList = lazy(() => import('../pages/verifi/client/list/List'));
  const RdrCaidList = lazy(() => import('../pages/verifi/caid/list/List'));
  const RdrCaidCreateForm = lazy(() => import('../pages/verifi/caid/form/CaidCreateForm'));

  /* Beceipt routes */
  const TransactionsList = lazy(() => import('../pages/beceipt/transactions/list/List'));
  const TransactionsDetail = lazy(() => import('../pages/beceipt/transactions/detail/Detail'));
  const BeceiptRequestsList = lazy(() => import('../pages/beceipt/request/list/List'));
  // const BeceiptClientList = lazy(() => import('../pages/beceipt/client/list/List'))

  // const UsersList = lazy(() => import('../pages/users/list/List'))
  // const UsersDetail = lazy(() => import('../pages/users/detail/Detail'))

  /* Monitor routes */
  // const DuplicateList = lazy(() => import('../pages/monitor/duplicate/list/List'))
  // const EthocaDuplicateList = lazy(() => import('../pages/monitor/ethocaDuplicate/list/List'))
  // const EthocaDuplicateDetail = lazy(() => import('../pages/monitor/ethocaDuplicate/detail/Detail'))
  
  // Settlement routes
  // const SettlementDuplicateList = lazy(() => import('../pages/settlement/duplicateArn/list/List'))
  // const RdrRecallList = lazy(() => import('../pages/settlement/rdrRecalledCase/list/List'))
  // const SettlementClientList = lazy(() => import('../pages/settlement/client/list/List'))

  const ContactUs = lazy(() => import('../pages/contactUs/ContactUs'));

  // const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        {/* <Route path='auth/*' element={<Navigate to={haveDashBoardPermission ? '/dashboard' : '/ethoca/alerts/list'} />} /> */}
        <Route path='auth/*' element={<Navigate to='/ethoca/alerts/list' />} />

        {/* Redirect to Dashboard on root route */}
        {/* <Route path='' element={<Navigate to={haveDashBoardPermission ? '/dashboard' : '/ethoca/alerts/list'} />} /> */}
        <Route path='' element={<Navigate to='/ethoca/alerts/list' />} />

        {/* Pages */}
        {/* Ethoca */}
        <Route path='ethoca/alerts/list' element={<AlertsList />} />
        <Route path='ethoca/alerts/upload-status/list' element={<UploadStatus />} />
        <Route path='ethoca/alerts/:id' element={<AlertsDetail />} />
        <Route path='ethoca/descriptors/list' element={<DescriptorList />} />
        <Route path='ethoca/clients/list' element={<ClientList />} />
        {/* <Route path='ethoca/clients/tree' element={<ClientTree />} /> */}
        <Route path='ethoca/clients/:id' element={<ClientDetail />} />
        <Route path='ethoca/enquiries/list' element={<EnquiriesList />} />
        <Route path='ethoca/enquiries/form' element={<EnquiriesCreateForm />} />
        {/* <Route path='ethoca/audit-logs/list' element={<AuditLogsList />} /> */}

        {/* Rdr */}
        <Route path='rdr/cases/list' element={<RdrCasesList />} />
        <Route path='rdr/clients/list' element={<RdrClientList />} />
        <Route path='rdr/caids/list' element={<RdrCaidList />} />
        <Route path='rdr/caids/form' element={<RdrCaidCreateForm />} />

        {/* Beceipt */}
        <Route path='beceipt/transactions/list' element={<TransactionsList />} />
        <Route path='beceipt/transactions/:id' element={<TransactionsDetail />} />
        <Route path='beceipt/requests/list' element={<BeceiptRequestsList />} />
        {/* <Route path='beceipt/clients/list' element={<BeceiptClientList />} /> */}

        {/* User */}
        {/* <Route path='users/list' element={<UsersList />} /> */}
        {/* <Route path='users/:name' element={<UsersDetail />} /> */}

        {/* Monitor */}
        {/* <Route path='monitor/duplicate/list' element={<DuplicateList />} />
        <Route path='monitor/duplicate-ethoca/list' element={<EthocaDuplicateList />} />
        <Route path='monitor/duplicate-ethoca/:arn' element={<EthocaDuplicateDetail />} />
        <Route path='monitor/recall-rdr/list' element={<RdrRecallList />} /> */}

        {/* Settlement */}
        {/* <Route path='settlement/duplicate-arn/list' element={<SettlementDuplicateList />} />
        <Route path='settlement/recall-rdr/list' element={<RdrRecallList />} />
        <Route path='settlement/clients/list' element={<SettlementClientList />} /> */}

        {/* Subscription */}
        <Route path='contact-us' element={<ContactUs />} />

        {/* Dashboard */}
        {/* <Route path='dashboard' element={<Dashboard />} /> */}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
